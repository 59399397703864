export const scrollTo = () => {

    const Button = document.getElementById("scrollto");
    const scrollContainer = document.getElementById("main");
    const target = document.getElementsByClassName("post-text")[0];
    const header = document.getElementsByTagName('header')[0];

    reachesTop(target, scrollContainer);

    Button?.addEventListener("click",() =>{
        scroll(target, scrollContainer);

    });





    function scroll(element, scrollContainer) {
        scrollContainer.scroll({
            behavior: 'smooth',
            left: 0,
            top: element.offsetTop
        });
    }

    function reachesTop(element, scrollContainer){

        scrollContainer.addEventListener("scroll", () => {
            if(element.offsetTop - scrollContainer.scrollTop - header.offsetHeight - element.offsetHeight <= 0){
                Button.classList.add("hide");
            }else{
                Button.classList.remove("hide");
            }
        });

    }


}

