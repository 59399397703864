const subMenu = document.querySelector(".submenu");
const subMenuContainer = document.querySelector(".submenu-container");
const menuItems = document.querySelectorAll(".menu-item.load-menu a");
const main = document.querySelector(".main");
const menuToggle = document.querySelector(".menu-toggle");
const menu = document.querySelector('.menu');
const logo = document.querySelector(".header-nav-sm .logo");
let html = "";

menuToggle.addEventListener("click", function (e) {

    menu.classList.toggle("menu-active");
    this.classList.toggle("active");
    main.classList.remove("blurred");

    if (menu.classList.contains("menu-active")) {
        subMenu.classList.add('hide');
        subMenuContainer.classList.add("hide");
        main.classList.remove("blurred");
        logo.classList.remove("active");
        Array.from(menuItems).forEach((menuItem) => {
            menuItem.parentElement.classList.remove("active");
        });
    }
});

subMenuContainer.addEventListener("click", function (e) {

    if (e.target == this) {
        subMenuContainer.classList.add("hide");
        subMenu.innerHTML = "";
        Array.from(menuItems).forEach((menuItem) => {
            menuItem.parentElement.classList.remove("active");
            main.classList.remove("blurred");
        });
        menu.classList.remove("menu-active");
        menuToggle.classList.remove("active");
    }

});


Array.from(menuItems).forEach((menuItem) => {

    menuItem.addEventListener("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        menuItem.style.pointerEvents = "none";
        html = "";
        subMenuContainer.classList.remove('hide');
        subMenu.classList.remove('hide');
        main.classList.add("blurred");

        const target = menuItem.getAttribute("href") + ".json";
        console.log("target= " + target);


        Array.from(menuItems).forEach((menuItem) => {
            menuItem.parentElement.classList.remove("active");
        });

        menuItem.parentElement.classList.add("active");

        fetch(target)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log(data);
                buildMenu(data);
                subMenu.innerHTML = html;
                showItems();
                menuItem.style.pointerEvents = "auto";

                const stateObj = { page: target};
                history.pushState(stateObj, "", target.replace(".json", "") );
            });

    });

});

window.addEventListener("popstate", function (event) {
    // You can handle the state change here and load the appropriate content
    // based on the state's "page" property.
    console.log("State changed:", event.state);
    html = "";
    subMenuContainer.classList.remove('hide');
    subMenu.classList.remove('hide');
    main.classList.add("blurred");

    fetch(event.state.page)
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            console.log(data);
            buildMenu(data);
            subMenu.innerHTML = html;
            showItems();
        });
});


const buildMenu = (data) => {

    if (data.filters) {

        html += "<div class='submenu-filters'>";
        html += "<ul class='submenu-categories'>";
        for (j = 0; j < data.filters.length; j++) {
            let filters = data.filters[j];
            html += "<li class='submenu-filter' data-target=" + filters['category-id'] + "><a href='#'>" + filters['category-title'] + "</a></li>";
        }
        html += "</ul>";
    }

    html += "<ul class='submenu-dates'>";
    for (i = 0; i < data["items"].length; i++) {

        var item = data.items[i];
        var itemDate = item["dateFilter"];


        if(i+1 < data["items"].length){
            var nextItem = data.items[i+1];
            var nextItemDate = nextItem["dateFilter"];
        }else{
            nextItem = null;
            nextItemDate = null;
        }

        if(i === 1){
            var active = "default";
        }else{
            var active = "";
        }


        if(itemDate && itemDate !== nextItemDate){
            html += "<li class='submenu-filter "+active+"' data-target=" + itemDate + "><a href='#'>" + itemDate + "</a></li>";

        }
    }

    html += "</ul'>";
    html += "</div>";

    html += "<div class='items "+ data["page"]+"'>";


    for (i = 0; i < data["items"].length; i++) {
        let item = data.items[i];
        if(data["page"] !== "actualites"){
            html += "<div data-grid='"+item['grid']+"' data-date='"+item['dateFilter']+"' class='submenu-item hide " + item['category'] +"'><a href='" + item['url'] + "'>" + item['title'] + "</a></div>";
        }else{
            html += "<div data-grid='"+item['grid']+"' data-date='"+item['dateFilter']+"' class='submenu-item hide " + item['category'] +"'><p>" + item['title'] + "</p></div>";
        }
    }

    html += "</div>";
    return html;
}

const showItems = () => {

    subMenuFilters = document?.querySelectorAll(".submenu-filter");

    const target = subMenuFilters[0]?.dataset.target;
    subMenuFilters[0]?.classList.add("active");

    const subMenuItems = document.getElementsByClassName("submenu-item");

    if (target) {
        Array.from(subMenuItems).forEach((subMenuItem) => {

            if (subMenuItem.classList.contains(target)) {
                subMenuItem.classList.remove("hide");
            } else {
                return false;
            }


        });
    } else {
        Array.from(subMenuItems).forEach((subMenuItem) => {
            subMenuItem.classList.remove("hide");
        });
    }

    Array.from(subMenuItems).forEach((subMenuItem) => {
        if (subMenuItem.classList.contains(target) || subMenuItem.dataset.date == target ) {
            if (subMenuItem.dataset.grid === "2") {
                subMenuItem.parentElement.classList.add('col-2');
            } else {
                subMenuItem.parentElement.classList.remove('col-2');
            }
        }
    });


    Array.from(subMenuFilters).forEach((subMenuFilter) => {

        subMenuFilter.addEventListener("click", function (e) {
            e.preventDefault();

           /* if(e.target.parentElement.parentElement.classList.contains("submenu-dates")){
                var Default = e.target.parentElement.parentElement.querySelector('.default');
                if(Default){
                    Default.classList.remove("default");
                }
            }*/

            const target = subMenuFilter.dataset.target;
            console.log(target);
            subMenuFilter.classList.add("active");

            const subMenuItems = document.getElementsByClassName("submenu-item");

            Array.from(subMenuFilters).forEach((subMenuFilter) => {
                subMenuFilter.classList.remove("active");
            });

            subMenuFilter.classList.add("active");

            Array.from(subMenuItems).forEach((subMenuItem) => {

                if (subMenuItem.classList.contains(target) || subMenuItem.dataset.date == target ) {
                    if(subMenuItem.dataset.grid ==="2"){
                        subMenuItem.parentElement.classList.add('col-2');
                    }else{
                        subMenuItem.parentElement.classList.remove('col-2');
                    }
                    subMenuItem.classList.remove("hide");
                } else {
                    subMenuItem.classList.add("hide");
                }

            });

            const filterDates = document.querySelector('.submenu-dates');

            if(subMenuFilter.dataset.target == "past"){
                filterDates.classList.add('show');
                var Current = filterDates.querySelector(".submenu-filter:first-of-type").dataset.target;
                filterDates.querySelector(".submenu-filter:first-of-type").classList.add("active");
               Array.from(subMenuItems).forEach((subMenuItem) =>{
                   if(subMenuItem.dataset.date == Current){
                       console.log(target);
                       console.log(subMenuItem.dataset.date);
                       subMenuItem.classList.remove('hide');
                   }else{
                       subMenuItem.classList.add('hide');
                   }
               });


            }else if (subMenuFilter.dataset.target == "ongoing"){
                filterDates.classList.remove('show');
            }


        });
    });
}
