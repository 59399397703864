export const marquee = (speed) => {
    const marqueeExists = document.getElementsByClassName('marquee').length > 0;


    if(marqueeExists) {
        const selector = '.marquee';
        const parentSelector = document.querySelector(selector);
        const clone = parentSelector.innerHTML;
        const firstElement = parentSelector.children[0];
        let i = 0;
        console.log(firstElement);
        parentSelector.insertAdjacentHTML('beforeend', clone);
        parentSelector.insertAdjacentHTML('beforeend', clone);

        setInterval(function () {
            firstElement.style.marginLeft = `-${i}px`;
            if (i > firstElement.clientWidth) {
                i = 0;
            }
            i = i + speed;
        }, 0);
    }
}


//after window is completed load
//1 class selector for marquee
//2 marquee speed 0.2


