import {collapse} from "./collapse";


document.addEventListener("DOMContentLoaded", () => {


    const gridItems = document.querySelectorAll('.load-page');
    const container = document.getElementById('loaded-post-container');
    const body = document.body;


    Array.from(gridItems).forEach((gridItem) => {

        gridItem.addEventListener("click", function (e) {

            console.log(container);

            e.preventDefault();
            const target = gridItem.getAttribute('href') + ".json";

            fetch(target)
                .then(function (response) {
                    return response.json();
                    console.log(response.json());
                })
                .then(function (data) {
                    const html = data;
                    console.log(html);
                    container.style.display = "block";
                    container.innerHTML = data;
                    Init(container);


                });

        });
    });

});


const Init = (container) => {

    const closeCrosses = document.querySelectorAll('.close-cross');
    const backButtons = document.querySelectorAll('.back');

    Array.from(closeCrosses).forEach((closeCross) => {
        closeCross.addEventListener("click", function () {

            container.style.display = "none";
        });
    });

    Array.from(backButtons).forEach((backButton) => {
        backButton.addEventListener("click", function () {

            container.style.display = "none";
        });
    });

    collapse();

}