import {scrollTo} from "./_modules/scrollTo";

__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { marquee } from "./_modules/marquee";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';


import './_modules/salon';
import './_modules/homeAnimation';
import './_modules/pageInfo';
import './_modules/gradient';
import './_modules/collapse';
import './_modules/submenu';
import './_modules/marquee';

document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();
  scrollTo();
  marquee(0.5);
  window.dispatchEvent(new Event('resize'));
});