export const collapse = () => {

    var collapsibles = document.getElementsByClassName("collapsible");
    var i;

    Array.from(collapsibles).forEach((coll) => {

        // Open on load if active
        if (coll.classList.contains("active") && window.innerWidth > 1024) {
            var content = coll.nextElementSibling;
            content.style.maxHeight = content.scrollHeight + "px";
        }else{
            var content = coll.nextElementSibling
            coll.classList.remove("active");
            content.classList.remove('collapsed');
        }

        // Open on click
        coll.addEventListener("click", function (e) {

            Array.from(collapsibles).forEach((coll) => {
                if (coll == this) {
                    return false
                } else {
                    var content = coll.nextElementSibling;
                        content.style.maxHeight = null;
                        content.classList.remove('collapsed');
                        coll.classList.remove("active");
                }

            });

            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
                content.classList.remove('collapsed');
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
                content.classList.add('collapsed');
            }
        });
    });


    window.addEventListener('resize', () => {

        Array.from(collapsibles).forEach((coll) => {
            if (coll.classList.contains("active")) {
                var content = coll.nextElementSibling;

                if (content.style.maxHeight) {
                    content.style.maxHeight = null;
                } else {
                    content.style.maxHeight = content.scrollHeight + "px";
                }
            }
        });

    });
}

collapse();